<template>
  <v-snackbar
    v-model="props.value"
    @change="$emit('update:modelValue', props.value)"
    :timeout="-1"
  >
    {{ text }}
    <template v-slot:actions>
      <v-btn :icon="mdiClose" @click="close"></v-btn>
    </template>
  </v-snackbar>
</template>

<script setup>
import { mdiClose } from '@mdi/js'
const emit = defineEmits(['update:modelValue'])

const props = defineProps({
  value: {
    type: Boolean,
    default: false,
  },
  text: {
    type: String,
    default: '',
  },
})

const close = function () {
  emit('update:modelValue', false)
}
</script>